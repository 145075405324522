import { createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

const checkPhone = (rule, value, callback) => {
  console.log(rule, value, callback);
  if (!value) {
    return callback(new Error('请输入电话号码'));
  }
  setTimeout(() => {
    if (value.length === 11) {
      callback();
    } else {
      callback(new Error('电话号码格式不正确'));
    }
  }, 1000);
};
export default {
  name: "ridePromotion",
  components: {},
  mixins: [mixins],
  data() {
    return {
      formRules: {
        tel: [{
          validator: checkPhone,
          trigger: 'blur'
        }]
      },
      checkDialog: false,
      checkForm: {
        id: null,
        checkStatus: null,
        checkMemo: null
      },
      sendDialog: false,
      sendForm: {
        id: null,
        sendType: '用户',
        tel: null
      },
      searchItems: [{
        prop: 'name',
        label: '活动名称'
      }, {
        prop: 'status',
        label: '活动状态',
        type: 'select',
        items: ['正常', '关闭']
      }, {
        prop: 'checkStatus',
        label: '审批状态',
        type: 'select',
        items: ['待审批', '审批通过', '审批不通过']
      }],
      columns: [{
        label: '活动名称',
        width: '180px',
        prop: 'name'
      }, {
        label: '活动编码',
        width: '180px',
        prop: 'code'
      }, {
        label: '使用端',
        width: '100px',
        prop: 'employ'
      }, {
        label: '活动时间',
        width: '180px',
        customRender: props => {
          return `${props.row.beginTime} ~ ${props.row.endTime}`;
        }
      }, {
        label: '券数量',
        prop: 'couponTimes'
      }, {
        label: '发放量',
        prop: 'couponSurplusTimes'
      }, {
        label: '失效方式',
        width: '100px',
        prop: 'couponExpirationType'
      }, {
        label: '失效时间',
        width: '170px',
        customRender: props => {
          if (props.row.couponExpirationType === '固定时间') {
            var _props$row$couponExpi;
            return `${(_props$row$couponExpi = props.row.couponExpirationTime) !== null && _props$row$couponExpi !== void 0 ? _props$row$couponExpi : '长期'}`;
          } else {
            return `${props.row.couponExpriationDynamicValue}  ${props.row.couponExpriationDynamicUnit}`;
          }
        }
      }, {
        label: '随机总金额',
        prop: 'totalAmount',
        width: '120px'
      }, {
        label: '单位',
        prop: 'unit'
      }, {
        label: '面额',
        customRender: props => {
          if (props.row.promotionType === '固定金额') {
            return `${props.row.money}`;
          } else {
            return `${props.row.minAmount} 至 ${props.row.maxAmount}`;
          }
        }
      }, {
        label: '审批状态',
        prop: 'checkStatus',
        width: '100px'
      }, {
        label: '活动状态',
        width: '100px',
        customRender: props => {
          if (this.$hasPermission('parkPromotion_off')) {
            return _createVNode(_resolveComponent("el-switch"), {
              "modelValue": props.row.status,
              "onUpdate:modelValue": $event => props.row.status = $event,
              "loading": this.switchLoading,
              "before-change": () => {
                return new Promise((resolve, reject) => {
                  this.offOron(props.row, resolve, reject);
                });
              }
            }, null);
          } else {
            return props.row.status ? '正常' : '关闭';
          }
        }
      }],
      topButtons: [{
        name: '新增',
        code: 'ridePromotion_add',
        type: 'primary',
        click: this.Add,
        permissions: ['ridePromotion_add']
      }],
      linkButtons: [{
        code: 'ridePromotion_edit',
        click: this.edit,
        permissions: ['ridePromotion_edit'],
        name: '编辑',
        show: row => {
          return row.checkStatus === '待审批';
        }
      }, {
        code: 'ridePromotion_check',
        click: this.check,
        permissions: ['ridePromotion_check'],
        name: '审批',
        show: row => {
          return row.checkStatus === '待审批';
        }
      }, {
        code: 'ridePromotion_provide',
        click: this.provide,
        permissions: ['ridePromotion_provide'],
        name: '发放',
        show: row => {
          return row.checkStatus === '审批通过';
        }
      }],
      switchLoading: false
    };
  },
  methods: {
    Add() {
      this.$router.push('/ridePromotionAdd');
    },
    /**
     * 编辑活动
     * @param row
     */
    edit(row) {
      this.$router.push({
        path: '/ridePromotionAdd',
        query: {
          id: row.id
        }
      });
    },
    /**
     * 开关活动
     * @param row
     */
    offOron(row, resolve, reject) {
      const loading = ElLoading.service();
      this.$api.business.ridePromotion.detail({
        id: row.id
      }).then(res => {
        if (res.code === 200) {
          loading.close();
          let mes = res.data.status ? '确认要关闭该活动吗?' : '确认要开启该活动吗';
          ElMessageBox.confirm(mes, '提示', {
            type: 'warning'
          }).then(() => {
            const toloading = ElLoading.service();
            this.switchLoading = true;
            this.$api.business.ridePromotion.offOn({
              id: row.id
            }).then(resa => {
              toloading.close();
              if (resa.code === 200) {
                ElMessage({
                  message: resa.message,
                  type: 'success'
                });
                return resolve(true);
              } else {
                ElMessage.error(resa.message);
                return reject(new Error('Error'));
              }
            }).finally(() => {
              toloading.close();
              this.switchLoading = false;
            });
          });
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    },
    /**
     * 审批活动
     * @param row
     */
    check(row) {
      this.checkDialog = true;
      this.checkForm = {
        id: row.id,
        checkStatus: null,
        checkMemo: null
      };
    },
    /**
     * 发放活动券
     * @param row
     */
    provide(row) {
      this.sendDialog = true;
      this.sendForm = {
        id: row.id,
        sendType: '车辆',
        tel: null,
        plateColor: null,
        plateNo: null
      };
    },
    doCheck(status) {
      const toloading = ElLoading.service();
      this.$api.business.ridePromotion.check({
        id: this.checkForm.id,
        checkStatus: status,
        checkMemo: this.checkForm.checkMemo
      }).then(resa => {
        toloading.close();
        if (resa.code === 200) {
          this.checkDialog = false;
          ElMessage({
            message: resa.message,
            type: 'success'
          });
          this.$refs.tablePage.fetch();
        } else {
          ElMessage.error(resa.message);
        }
      }).finally(() => {
        toloading.close();
      });
    },
    doProvide() {
      this.$refs.sendFormRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$refs.tablePage.fetch();
              this.sendDialog = false;
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.sendForm.sendType === '车辆') {
            this.sendForm.tel = null;
          } else {
            this.sendForm.plateNo = null;
            this.sendForm.plateColor = null;
          }
          this.$api.business.ridePromotion.provide(this.sendForm).then(callBack);
        }
      });
    }
  }
};